import React, { useState, useEffect } from 'react';
import './Profile.css';

function Profile() {
  const [name, setName] = useState('');
  const [surname, setSurname] = useState('');
  const [email, setEmail] = useState('');
  const [username, setUsername] = useState('');
  const [profileImage, setProfileImage] = useState(null);
  const [purchasedItems, setPurchasedItems] = useState([]);



  useEffect(() => {
    const storedData = JSON.parse(localStorage.getItem('userData'));
    
    let loggedInUser = localStorage.getItem('loggedInUser');

    if (storedData && loggedInUser && storedData[loggedInUser]) {
      const { name, surname, email } = storedData[loggedInUser];
      setPurchasedItems(JSON.parse(localStorage.getItem('purchasedItems')));
      console.log(purchasedItems);
      
      setName(name);
      setSurname(surname);
      setEmail(email);
      setUsername(loggedInUser);
      
    } else if (storedData && !loggedInUser) {
      const firstKey = Object.keys(storedData)[0];
      localStorage.setItem('loggedInUser', firstKey);
      loggedInUser = firstKey;
      const { name, surname, email } = storedData[loggedInUser];
      setPurchasedItems(JSON.parse(localStorage.getItem('purchasedItems')));
      console.log(purchasedItems);

      setName(name);
      setSurname(surname);
      setEmail(email);
      setUsername(loggedInUser);
      
    }

    const storedProfileImage = localStorage.getItem('profileImage');
    if (storedProfileImage) {
      setProfileImage(storedProfileImage);
    }
  }, []);

  const handleImageChange = (e) => {
    if (e.target.files && e.target.files[0]) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const imageData = e.target.result;
        setProfileImage(imageData);
        localStorage.setItem('profileImage', imageData);
      };
      reader.readAsDataURL(e.target.files[0]);
    }
  };

  return (
    <div className="profile">
      <h1>Profile</h1>
      <div className="profile-info">
        <div>
          <p><strong>Name:</strong> {name}</p>
          <p><strong>Surname:</strong> {surname}</p>
          <p><strong>Email:</strong> {email}</p>
        </div>
        <div>
          {profileImage && <img src={profileImage} alt="Profile Image" className="profile-image" />}
        </div>
      </div>
      <div className="profile-image-input">
        <input type="file" accept="image/*" onChange={handleImageChange} />
      </div>
      <div>
        <h2>Purchased Items</h2>
        <ul>
          {purchasedItems && purchasedItems.map((item, index) => (
            <li key={index} className="purchased-item">
              <div
                className="purchased-item-image"
                style={{ backgroundImage: `url(${item.coverImage})` }}
              ></div>
              <div className="purchased-item-title">{item.title}</div>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
}


export default Profile;
